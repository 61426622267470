import {
  PlaceAggregationsInput,
  PlaceAggregationValueField,
  PlaceType,
  PropertyListingConnectionInput,
  PropertyListingSortKey,
  PropertyListingStatus,
  SortOrder,
} from '@unreserved-frontend-v2/api/generated/graphql/types'

export const RECENTLY_LISTED_VARIABLES: PropertyListingConnectionInput = {
  first: 3,
  offset: 0,
  sortKey: PropertyListingSortKey.DateListed,
  sortOrder: SortOrder.Desc,
  listingStatuses: [PropertyListingStatus.Active, PropertyListingStatus.ConditionallySold],
}

export const RECENTLY_SOLD_VARIABLES: PropertyListingConnectionInput = {
  first: 3,
  offset: 0,
  sortKey: PropertyListingSortKey.DateSold,
  sortOrder: SortOrder.Desc,
  listingStatuses: [PropertyListingStatus.Sold],
}

// On desktop we show the first 3 at the top of the page, and the other 8 at the bottom, so need 11 in total
export const RECENTLY_SOLD_DESKTOP_VARIABLES: PropertyListingConnectionInput = {
  ...RECENTLY_SOLD_VARIABLES,
  first: 11,
}

// On desktop we show the first 3 at the top of the page, and the other 8 at the bottom, so need 11 in total
export const RECENTLY_LISTED_DESKTOP_VARIABLES: PropertyListingConnectionInput = {
  ...RECENTLY_LISTED_VARIABLES,
  first: 11,
}

export const BROWSE_BY_PLACE_DEFAULT_VARIABLES: PlaceAggregationsInput = {
  placeTypes: [PlaceType.City, PlaceType.Region],
  valueField: PlaceAggregationValueField.ListingPrice,
  limit: 34,
}

export const DEFAULT_BROWSE_BY_REGION_SLUG = 'ontario-ca'
