import { dehydrate, DehydratedState, QueryClient, QueryKey } from '@tanstack/react-query'
import { ClientError } from 'graphql-request'
import { getErrorCode } from './utils'

/**
 * Wrapper function that prefetches data for us on the server.
 *
 * If an error is thrown while fetching, the errorCode will be set so that we can redirect/display the relevant
 * error page and the dehydrated state will be undefined.
 *
 * If no error is thrown, the error code will  be 0 and the dehydrated state will be populated to what should
 * be returned to the client.
 *
 */
export const ssrPrefetch = async <DataReturn = unknown>(
  query: QueryKey,
  fetcher: Promise<unknown>,
  queryClient: QueryClient = new QueryClient()
): Promise<{ errorCode: number | undefined; dehydratedState: DehydratedState | undefined; data: DataReturn }> => {
  let errorCode = undefined

  try {
    const response = await queryClient.fetchQuery(query, () =>
      fetcher.catch((err: ClientError) => {
        errorCode = getErrorCode(err)

        // Based on comments in the react-query docs, we re-throw the error here to make sure that the "error" object
        // is populated when client is hydrated.
        // https://tanstack.com/query/v4/docs/react/guides/query-functions#handling-and-throwing-errors
        throw err
      })
    )

    return {
      errorCode,
      dehydratedState: errorCode ? undefined : dehydrate(queryClient, { shouldDehydrateQuery: () => true }),
      data: response as DataReturn,
    }
  } catch (err) {
    return {
      errorCode: getErrorCode(err as ClientError),
      dehydratedState: undefined,
      data: err as DataReturn,
    }
  }
}
